<template>
  <ResponsiveDialog :visible="visible" header="Change Permissions" width="500px" @update:visible="$emit('update:visible', false)">
    <PVCard>
      <template #content>
        <div class="flex pvflex flex-column">
          <ContactConfigSwitchComponent label="Super Admin" hint="Toggles on/off super admin features">
            <input type="checkbox" :checked="superAdmin" style="margin-top: 12px;" @change="toggleSuperAdmin"/>
          </ContactConfigSwitchComponent>
          <div v-for="permission in permissionsKeys" :key="permission" wrap>
            <ContactConfigSwitchComponent :label="permission + ' view'" hint="Toggles on/off viewing">
              <input type="checkbox" :checked="permissions[permission].view" style="margin-top: 12px;" @change="togglePermissions(permission, 'view', !permissions[permission].view)"/>
            </ContactConfigSwitchComponent>
            <ContactConfigSwitchComponent :label="permission + ' create'" hint="Toggles on/off creating">
              <input type="checkbox" :checked="permissions[permission].create" style="margin-top: 12px;" @change="togglePermissions(permission, 'create', !permissions[permission].create)"/>
            </ContactConfigSwitchComponent>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="flex pvflex flex-row align-center justify-content-end mt-2">
          <PVButton raised class="uppercase nowrap" severity="error" label="Reset" @click="authentication.togglePermissionsReset()"/>
        </div>
      </template>
    </PVCard>
  </ResponsiveDialog>
</template>

<script>
import ContactConfigSwitchComponent from "../../../patients/patient/contacts/ContactConfigSwitchComponent.vue";
import ResponsiveDialog from "../../dialogs/ResponsiveDialog.vue";

export default {
  name: "SuperPermissionsToggleDialog",
  components: { ResponsiveDialog, ContactConfigSwitchComponent },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      permissionsKeys: [
        'patients',
        'contacts',
        'monitoring',
        'tags',
        'companies',
        'events',
        'pack'
      ]
    }
  },
  computed: {
    superAdmin() {
      return this.authentication.access.superAdmin;
    },
    permissions() {
      return this.authentication.access.permissions;
    }
  },
  methods: {
    toggleSuperAdmin() {
      this.authentication.toggleSuperAdmin(!this.superAdmin);
    },
    togglePermissions(parent, permission, value) {
      this.authentication.updatePermission({
        parent: parent,
        permission: permission,
        status: value
      });
    }
  }
}
</script>

<style scoped>

xxinput[type=checkbox] {
    min-width: 18px;
    min-height: 18px;
}

xxinput[type=checkbox]:hover {
    cursor: pointer;
}

</style>
