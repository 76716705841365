<template>
  <div class="flex flex-row justify-content-between flex-wrap">
    <NavigationDrawer :styleNEW="styleNEW"/>
    <div>
      <PVButton label="Log Out" icon="material-icons exit_to_app" @click="authenticationStore.logout()"/>
    </div>
  </div>
</template>

<script>
import NavigationDrawer from "./navigation/NavigationDrawer.vue";
import { useAuthStore } from "@/store/authentication.js";

export default {
  name: 'NavigationToolBar',
  components: { NavigationDrawer },
  props: {
    styleNEW:{
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const authenticationStore = useAuthStore();
    return { authenticationStore };
  },
}
</script>

<style scoped>

</style>
