import { setup } from './setup.js';
import { router } from './router/index.js';

import App from './views/App.vue';
import { createPinia, PiniaVuePlugin, defineStore } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { useNotificationsStore } from "@/store/notification.js";
import { useAuthStore } from "@/store/authentication.js";
import { useVersioningStore } from "@/store/versioning.js";
import { usePreferencesStore } from "@/store/preferences.js";
import { useCompaniesStore } from "@/store/companies.js";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

// Handle common errors inbound
window.axios.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response === undefined) return Promise.reject(error);
  if (error.response.status == 401) {
    // Handle unauthorised by force logging out our user and then putting them to the login page
    const authentication = useAuthStore();
    authentication.checkAuthState();
  }

  if (error.response.status == 500) {
    const notiStore = useNotificationsStore();
    notiStore.addError('An error has occurred, if this persists please contact the system administrator.');
  }

  // Check if we have generic errors and fill the error state and module
  if (error.response.data.errors) {
    const notiStore = useNotificationsStore();
    notiStore.addError(error.response.data.errors);
  }

  return Promise.reject(error);
});

router.beforeEach((to, from, next) => {
  document.title = to.name;
  const versStore = useVersioningStore(pinia);
  versStore.checkVersion();
  next();
  if (versStore.updateAvailable) {
    versStore.updateSite();
  }
});

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
setup(App, router, pinia);

{
  const auth = useAuthStore();
  auth.boot();
  const notiStore = useNotificationsStore();
  notiStore.clearMessages();
  //        this.commit('loadCompanies');
  const vers = useVersioningStore();
  vers.checkInternalVersions();
  vers.bootVersion();
  const prefs = usePreferencesStore();
  prefs.updateTimezone(prefs.timezone == null ? false : prefs.timezone.useBrowserTime);
}


