<template>
  <div class="ym-bg-primary flex pvflex flex-row justify-content-between">
    <PVButton icon="material-icons menu" class="bg-transparent text-black" @click="toggleDrawer"/>
    <div v-if="authentication.isLoggedIn" class="justify-content-end">
      <PVButton icon="material-icons exit_to_app" class="bg-primary p-button-text uppercase nowrap" severity="primary" label="Log Out" @click="authentication.logout()"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "ToolBar",
}
</script>

<style scoped>

</style>
